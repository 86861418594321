import { javascriptGenerator } from "blockly/javascript";
function createStarImportBlock(name) {
    let blockName = `import_${name}`;
    javascriptGenerator.forBlock[blockName] = (block, generator) => {
        return `import * as ${name} from "${name}";\n`;
    };
    return {
        type: blockName,
        init(bloc) {
            bloc.appendDummyInput()
                .appendField(`import * as ${name} from "${name}"`);
            bloc.setColour(160);
            bloc.setNextStatement(true);
            bloc.setPreviousStatement(true);
        }
    };
}
export function getImportBlocks() {
    return [
        createStarImportBlock("adc"),
        createStarImportBlock("gpio"),
        createStarImportBlock("i2c"),
        createStarImportBlock("ledc"),
        createStarImportBlock("simpleradio"),
        createStarImportBlock("wifi")
    ];
}
