import { jsx as _jsx } from "react/jsx-runtime";
import React, { Component } from "react";
import { BlocklyWorkspace } from "react-blockly";
import { javascriptGenerator } from "blockly/javascript";
import { svgResize } from "blockly";
import { getJaculusToolbos as getJaculusToolbox } from "../blockly-gen/jaculusBlocks";
const toolbox = getJaculusToolbox();
const defaultJson = {};
export class BlocklyPane extends Component {
    blocklyEditor = React.createRef();
    blocklyArea = React.createRef();
    workspace = null;
    getJS() {
        try {
            if (!this.workspace) {
                return "";
            }
            let code = javascriptGenerator.workspaceToCode(this.workspace);
            return code;
        }
        catch (e) {
            console.error(e);
            throw e;
        }
    }
    resize() {
        if (this.workspace) {
            svgResize(this.workspace);
        }
    }
    render() {
        return (_jsx(BlocklyWorkspace, { className: "blockly-workspace", onWorkspaceChange: (workspace) => { this.workspace = workspace; }, workspaceConfiguration: {
                renderer: "zelos",
                grid: {
                    spacing: 20,
                    length: 3,
                    colour: "#ccc",
                    snap: true
                },
                zoom: {
                    controls: true,
                    wheel: true,
                    startScale: 1.0,
                    maxScale: 3,
                    minScale: 0.3,
                    scaleSpeed: 1.2
                }
            }, toolboxConfiguration: toolbox, initialJson: this.props.defaultJson ? JSON.parse(this.props.defaultJson) : defaultJson, onJsonChange: (json) => { this.props.onChange?.(JSON.stringify(json)); } }));
    }
}
