import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from "react";
import { Editor, State } from "./Editor";
import { NavigationBar } from "./NavigationBar";
import { ControlBar } from "./ControlBar";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import { Console } from "./Console";
export class Root extends Component {
    editor;
    console;
    device = null;
    constructor(props) {
        super(props);
        this.editor = React.createRef();
        this.console = React.createRef();
        this.loadEditorState();
    }
    async getJS() {
        if (this.editor.current === null) {
            throw new Error("Editor not initialized");
        }
        return this.editor.current.getJS();
    }
    startMonitor() {
        if (!this.device) {
            throw new Error("No device");
        }
        this.device.programOutput.onData((data) => {
            this.console.current?.write(data.toString());
        });
        this.device.programError.onData((data) => {
            this.console.current?.write(data.toString());
        });
    }
    stopMonitor() {
        if (!this.device) {
            throw new Error("No device");
        }
    }
    loadEditorState() {
        let state = localStorage.getItem("editorState");
        if (state === null) {
            return;
        }
        console.log("load", state);
        return State.fromString(state);
    }
    handleEditorChange(state) {
        localStorage.setItem("editorState", state.toString());
    }
    render() {
        return _jsxs("div", { className: "root", children: [_jsx(NavigationBar, { onPaneChange: (pane) => this.editor.current?.selectPane(pane) }), _jsxs(ReflexContainer, { className: "pane-view", orientation: "vertical", children: [_jsx(ReflexElement, { minSize: 100, flex: 4, onResize: () => this.editor.current?.resize(), children: _jsx(Editor, { ref: this.editor, onChange: (state) => this.handleEditorChange(state), initialState: this.loadEditorState() }) }), _jsx(ReflexSplitter, { className: "splitter" }), _jsx(ReflexElement, { minSize: 100, flex: 1, children: _jsx(Console, { ref: this.console }) })] }), _jsx(ControlBar, { getCode: () => { return this.getJS(); }, onConnect: (device) => { this.device = device; this.startMonitor(); }, onDisconnect: () => { this.stopMonitor(); this.device = null; } })] });
    }
}
