import { jsx as _jsx } from "react/jsx-runtime";
import { Component } from "react";
import Editor from "@monaco-editor/react";
export class TextPane extends Component {
    monacoEditor = null;
    disableOnChange = false;
    getJS() {
        if (this.monacoEditor === null) {
            return "";
        }
        return this.monacoEditor.getValue();
    }
    setJS(code, raiseOnChange = false) {
        if (this.monacoEditor === null) {
            return;
        }
        this.disableOnChange = !raiseOnChange;
        this.monacoEditor.setValue(code);
        this.disableOnChange = false;
    }
    raiseOnChange(value) {
        if (this.disableOnChange) {
            return;
        }
        this.props.onChange?.(value ?? "");
    }
    render() {
        return (_jsx(Editor, { width: "100%", height: "100%", language: "javascript", defaultValue: this.props.defaultValue ?? "// Write your code here", theme: "vs-dark", options: {
                minimap: {
                    enabled: false
                },
                readOnly: true
            }, onMount: (editor) => { this.monacoEditor = editor; }, onChange: (value) => { this.raiseOnChange(value); } }));
    }
}
