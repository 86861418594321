import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from "react";
import { BlocklyPane } from "./BlocklyPane";
import { TextPane } from "./TextPane";
import { Pane } from "./types";
import { ReduceRate } from "../debounce";
export class State {
    pane;
    content;
    constructor(pane, content) {
        this.pane = pane;
        this.content = content;
    }
    toString() {
        return JSON.stringify(this);
    }
    static fromString(str) {
        let obj = JSON.parse(str);
        return new State(obj.pane, obj.content);
    }
}
export class Editor extends Component {
    textEditor;
    blocklyEditor;
    state;
    resizeDebounce;
    constructor(props) {
        super(props);
        this.textEditor = React.createRef();
        this.blocklyEditor = React.createRef();
        this.state = {
            currentPane: this.props.initialState?.pane ?? Pane.Blocks
        };
        this.resizeDebounce = new ReduceRate(() => this._doResize(), 5);
    }
    selectPane(pane) {
        if (this.state.currentPane === pane) {
            return;
        }
        this.setState({
            currentPane: pane
        });
        if (pane === Pane.Text) {
            let code = this.blocklyEditor.current?.getJS();
            if (code === undefined) {
                code = "";
            }
            this.textEditor.current?.setJS(code);
        }
        else {
            // TODO: update blockly editor
        }
    }
    getJS() {
        if (this.state.currentPane === Pane.Text) {
            if (this.textEditor.current === null) {
                return "";
            }
            return this.textEditor.current.getJS();
        }
        else {
            if (this.blocklyEditor.current === null) {
                return "";
            }
            return this.blocklyEditor.current.getJS();
        }
    }
    resize() {
        this.resizeDebounce.call();
    }
    _doResize() {
        if (this.state.currentPane === Pane.Text) {
            // this.textEditor.current?.resize();
        }
        else {
            this.blocklyEditor.current?.resize();
        }
    }
    render() {
        return (_jsxs("div", { className: "editor", children: [_jsx("div", { className: "editor-pane", style: { display: this.state.currentPane === Pane.Text ? undefined : "none" }, children: _jsx(TextPane, { ref: this.textEditor, onChange: (code) => this.props.onChange?.(new State(Pane.Text, code)), defaultValue: this.props.initialState?.pane === Pane.Text ? this.props.initialState.content : undefined }) }), _jsx("div", { className: "editor-pane", style: { display: this.state.currentPane === Pane.Blocks ? undefined : "none" }, children: _jsx(BlocklyPane, { ref: this.blocklyEditor, onChange: (code) => this.props.onChange?.(new State(Pane.Blocks, code)), defaultJson: this.props.initialState?.pane === Pane.Blocks ? this.props.initialState.content : undefined }) })] }));
    }
}
