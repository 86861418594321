import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Component } from "react";
import { JacDevice } from "../jac-tools/device/jacDevice";
import { WebSerialStream } from "../webSerialStream";
import { Buffer } from "buffer";
export class ControlBar extends Component {
    connected = false;
    device = null;
    connect() {
        this.connected = true;
        let port = navigator.serial.requestPort();
        port.then(async (port) => {
            await port.open({ baudRate: 921600, bufferSize: 10240 }); // TODO: make this configurable
            let stream = new WebSerialStream(port);
            this.device = new JacDevice(stream);
            this.forceUpdate();
            this.props.onConnect?.call(this, this.device);
        });
    }
    async disconnect() {
        this.props.onDisconnect?.call(this);
        this.connected = false;
        await this.device?.destroy();
        this.forceUpdate();
    }
    async version() {
        if (!this.connected || !this.device) {
            return;
        }
        try {
            let version = await this.device.controller.version();
            alert("Version: " + version);
        }
        catch (e) {
            alert("Failed to get version: " + e);
        }
    }
    uploading = false;
    async upload() {
        if (this.uploading) {
            return;
        }
        if (!this.connected || !this.device) {
            return;
        }
        let device = this.device;
        this.uploading = true;
        // try {
        await (async () => {
            let code = await this.props.getCode.call(this);
            console.log("Uploading code: " + code);
            await device.controller.lock().catch((err) => {
                console.log("Error locking device: " + err + "\n");
            });
            await device.controller.stop().catch((err) => {
                console.log("Error stopping device: " + err);
            });
            const cmd = await device.uploader.writeFile("code/index.js", Buffer.from(code, "utf-8")).catch((err) => {
                throw new Error("Error uploading: " + err);
            });
            console.log(cmd.toString() + "\n");
            await device.controller.start("index.js").catch((err) => {
                throw new Error("Error starting program: " + err);
            });
            await device.controller.unlock().catch((err) => {
                throw new Error("Error unlocking device: " + err);
            });
        })();
        // }
        this.uploading = false;
    }
    async stop() {
        if (!this.connected || !this.device) {
            return;
        }
        try {
            await this.device.controller.lock().catch((err) => {
                console.log("Error locking device: " + err + "\n");
            });
            await this.device.controller.stop();
            await this.device.controller.unlock().catch((err) => {
                console.log("Error unlocking device: " + err + "\n");
            });
        }
        catch (e) {
            alert("Failed to stop program: " + e);
        }
    }
    async restart() {
        if (!this.connected || !this.device) {
            return;
        }
        try {
            await this.device.controller.lock().catch((err) => {
                console.log("Error locking device: " + err + "\n");
            });
            await this.device.controller.stop().catch((err) => {
                console.log("Error stopping device: " + err);
            });
            await this.device.controller.start("index.js").catch((err) => {
                console.log("Error starting program: " + err);
            });
            await this.device.controller.unlock().catch((err) => {
                console.log("Error unlocking device: " + err + "\n");
            });
        }
        catch (e) {
            alert("Failed to restart program: " + e);
        }
    }
    getContent() {
        if (this.connected) {
            return (_jsxs("div", { className: "control-connected", children: [_jsx("button", { onClick: () => this.disconnect(), className: "connected-button", children: "Disconnect" }), _jsx("button", { onClick: () => this.upload(), className: "connected-button", children: "Upload" }), _jsx("button", { onClick: () => this.version(), className: "connected-button", children: "Version" }), _jsx("button", { onClick: () => this.stop(), className: "connected-button", children: "Stop" }), _jsx("button", { onClick: () => this.restart(), className: "connected-button", children: "Restart" })] }));
        }
        return (_jsx("div", { className: "control-disconnected", children: _jsx("button", { onClick: () => this.connect(), className: "disconnected-button", children: "Connect" }) }));
    }
    render() {
        return (_jsx("div", { className: "control-bar", children: this.getContent() }));
    }
}
