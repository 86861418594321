import { jsx as _jsx } from "react/jsx-runtime";
import React, { Component } from "react";
export class Console extends Component {
    console;
    constructor(props) {
        super(props);
        this.console = React.createRef();
    }
    write(text) {
        if (this.console.current === null) {
            return;
        }
        this.console.current.innerHTML += text.replace(/\n/g, "<br>");
    }
    render() {
        return (_jsx("div", { className: "console", ref: this.console, children: " " }));
    }
}
