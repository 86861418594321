export class Debounce {
    timeout;
    callback;
    time;
    constructor(callback, time) {
        this.timeout = null;
        this.callback = callback;
        this.time = time;
    }
    call() {
        if (this.timeout !== null) {
            clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
            this.callback();
        }, this.time);
    }
}
export class ReduceRate {
    timeout;
    callback;
    time;
    constructor(callback, time) {
        this.timeout = null;
        this.callback = callback;
        this.time = time;
    }
    call() {
        if (this.timeout !== null) {
            return;
        }
        this.timeout = setTimeout(() => {
            this.callback();
            this.timeout = null;
        }, this.time);
    }
}
