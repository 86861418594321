import * as Blockly from "blockly";
import { javascriptGenerator } from "blockly/javascript";
export function getCoreBlocks() {
    javascriptGenerator.forBlock["exprStatement"] = (block, generator) => {
        let expr = generator.valueToCode(block, "expr", 0);
        if (block.getFieldValue("behaviour") === "await") {
            return `(await ${expr});\n`;
        }
        return `(${expr});\n`;
    };
    return [
        {
            type: "exprStatement",
            init(bloc) {
                bloc.appendValueInput("expr")
                    .setCheck(null)
                    .appendField(new Blockly.FieldDropdown([
                    ["await", "await"],
                    ["ignore", "ignore"]
                ]), "behaviour");
                bloc.setColour(160);
                bloc.setNextStatement(true);
                bloc.setPreviousStatement(true);
            }
        }
    ];
}
