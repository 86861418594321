import { Buffer } from "buffer";
export class WebSerialStream {
    callbacks = {};
    port;
    reader;
    writer;
    constructor(port) {
        this.port = port;
        let reader = port.readable?.getReader();
        if (!reader) {
            throw new Error("No reader");
        }
        let writer = port.writable?.getWriter();
        if (!writer) {
            throw new Error("No writer");
        }
        this.reader = reader;
        this.writer = writer;
        this.runReader();
    }
    async runReader() {
        try {
            while (true) { // eslint-disable-line no-constant-condition
                const { done, value } = await this.reader.read();
                if (done) {
                    this.reader.releaseLock();
                    if (this.callbacks["end"]) {
                        this.callbacks["end"]();
                    }
                    break;
                }
                if (this.callbacks["data"]) {
                    this.callbacks["data"](Buffer.from(value));
                }
            }
        }
        catch (err) {
            console.error("Reader error: " + err);
            if (this.callbacks["error"]) {
                this.callbacks["error"](err);
            }
        }
        console.log("Reader done");
    }
    put(c) {
        this.writer.write(Buffer.from([c]));
    }
    write(buf) {
        const bufCopy = Buffer.from(buf);
        console.log("Writing: " + bufCopy.toString("hex"));
        this.writer.write(bufCopy);
    }
    onData(callback) {
        this.callbacks["data"] = callback;
    }
    onEnd(callback) {
        this.callbacks["end"] = callback;
    }
    onError(callback) {
        this.callbacks["error"] = callback;
    }
    destroy() {
        // TBA: Check API doc
        // if (this.port.state === "closed") {
        //     return Promise.resolve();
        // }
        return new Promise((resolve, reject) => {
            if (this.callbacks["end"]) {
                const end = this.callbacks["end"];
                this.callbacks["end"] = () => {
                    end();
                    resolve();
                };
            }
            else {
                this.callbacks["end"] = () => {
                    resolve();
                };
            }
            if (this.callbacks["error"]) {
                const error = this.callbacks["error"];
                this.callbacks["error"] = (err) => {
                    error(err);
                    reject(err);
                };
            }
            else {
                this.callbacks["error"] = (err) => {
                    reject(err);
                };
            }
            (async () => {
                console.log("closing stream");
                await this.reader.cancel();
                await this.writer.abort();
                await this.port.close();
            })();
        });
    }
}
