import * as ts from "typescript";
import * as Blockly from "blockly";
import { javascriptGenerator } from "blockly/javascript";
const existingBlocks = {};
function getUniqueName(name) {
    let count = existingBlocks[name] ?? 0;
    existingBlocks[name] = count + 1;
    return count === 0 ? name : `${name}_${count}`;
}
function getAst(dts) {
    return ts.createSourceFile("in.d.ts", dts, {
        languageVersion: ts.ScriptTarget.ES2020,
        jsDocParsingMode: ts.JSDocParsingMode.ParseAll
    }, undefined, ts.ScriptKind.TS);
}
function getJsDoc(node) {
    let docs = node.jsDoc;
    if (docs === undefined) {
        return undefined;
    }
    let doc = docs.find((doc) => doc.comment.startsWith("#jacly"));
    return doc?.comment;
}
function simpleName(type) {
    switch (type) {
        case "number":
            return "Number";
        case "string":
            return "String";
        case "boolean":
            return "Boolean";
        default:
            return type;
    }
}
function simpleCheck(type) {
    switch (type) {
        case "any":
            return null;
        case "void":
            throw new Error("Invalid type: void");
        default:
            return simpleName(type);
    }
}
function typeToString(type) {
    switch (type.kind) {
        case "simple":
            return simpleName(type.type) ?? "any";
        case "promise":
            return `Promise<${typeToString(type.type)}>`;
        case "array":
            return `${typeToString(type.type)}[]`;
        case "object":
            return "{\n" + type.type.properties.map((prop) => {
                return `${prop.name}: ${typeToString(prop.type)},`;
            }).join("\n") + "\n}";
        case "function":
            return `(${type.type.args.map((arg) => {
                return `${arg.name}: ${typeToString(arg.type)}`;
            }).join(", ")}) => ${typeToString(type.type.returnType)}`;
        default:
            throw new Error("Unsupported type: " + type.kind);
    }
}
function simple(type) {
    return { kind: "simple", type };
}
function promise(type) {
    return { kind: "promise", type };
}
function getTypeReference(node) {
    if (node.typeName.kind !== ts.SyntaxKind.Identifier) {
        throw new Error("Type reference is not an identifier");
    }
    let identifier = node.typeName;
    let typeName = identifier.text;
    switch (typeName) {
        case "Promise":
            if (node.typeArguments === undefined || node.typeArguments.length !== 1) {
                throw new Error("Promise type has no type arguments");
            }
            return promise(getType(node.typeArguments[0]));
        default:
            if (node.typeArguments !== undefined) {
                throw new Error("Type reference has type arguments");
            }
            return simple(typeName);
    }
}
function getTypeFunction(node) {
    return { kind: "function", type: getFunctionSignature(node) };
}
function getTypeProperty(node) {
    if (node.name === undefined) {
        throw new Error("Property has no name");
    }
    if (node.name.kind !== ts.SyntaxKind.Identifier) {
        throw new Error("Property name is not an identifier");
    }
    let identifier = node.name;
    if (node.type === undefined) {
        throw new Error("Property has no type");
    }
    return {
        name: identifier.text,
        type: getType(node.type)
    };
}
function getTypeMethod(node) {
    if (node.name === undefined) {
        throw new Error("Method has no name");
    }
    if (node.name.kind !== ts.SyntaxKind.Identifier) {
        throw new Error("Method name is not an identifier");
    }
    let identifier = node.name;
    return {
        name: identifier.text,
        type: {
            kind: "function",
            type: getFunctionSignature(node)
        }
    };
}
function getTypeLiteral(node) {
    const properties = [];
    node.members.forEach((member) => {
        switch (member.kind) {
            case ts.SyntaxKind.PropertySignature:
                properties.push(getTypeProperty(member));
                break;
            case ts.SyntaxKind.MethodSignature:
                properties.push(getTypeMethod(member));
                break;
            default:
                throw new Error("Unsupported type literal member: " + ts.SyntaxKind[member.kind]);
        }
    });
    return { properties };
}
function getStringLiteral(node) {
    return node.text;
}
function getTypeUnionType(node) {
    const strings = [];
    node.types.forEach((type) => {
        if (type.kind !== ts.SyntaxKind.LiteralType) {
            throw new Error("Type literal member is not a literal type");
        }
        const lit = type;
        if (lit.literal.kind !== ts.SyntaxKind.StringLiteral) {
            throw new Error("Type literal member is not a string literal");
        }
        strings.push(getStringLiteral(lit.literal));
    });
    return { kind: "str_enum", type: strings };
}
function getLiteralType(node) {
    if (node.literal.kind === ts.SyntaxKind.StringLiteral) {
        return { kind: "literal", type: {
                kind: "string",
                value: getStringLiteral(node.literal)
            } };
    }
    if (node.literal.kind === ts.SyntaxKind.NumericLiteral) {
        return { kind: "literal", type: {
                kind: "number",
                value: node.literal.text
            } };
    }
    if (node.literal.kind === ts.SyntaxKind.TrueKeyword) {
        return { kind: "literal", type: {
                kind: "boolean",
                value: "true"
            } };
    }
    if (node.literal.kind === ts.SyntaxKind.FalseKeyword) {
        return { kind: "literal", type: {
                kind: "boolean",
                value: "false"
            } };
    }
    throw new Error("Unsupported literal type: " + ts.SyntaxKind[node.literal.kind]);
}
function getType(node) {
    switch (node.kind) {
        case ts.SyntaxKind.NumberKeyword:
            return simple("number");
        case ts.SyntaxKind.StringKeyword:
            return simple("string");
        case ts.SyntaxKind.BooleanKeyword:
            return simple("boolean");
        case ts.SyntaxKind.VoidKeyword:
            return simple("void");
        case ts.SyntaxKind.AnyKeyword:
            return simple("any");
        case ts.SyntaxKind.LiteralType:
            return getLiteralType(node);
        case ts.SyntaxKind.TypeReference:
            return getTypeReference(node);
        case ts.SyntaxKind.FunctionType:
            return getTypeFunction(node);
        case ts.SyntaxKind.UnionType:
            return getTypeUnionType(node);
        default:
            throw new Error("Unsupported type: " + ts.SyntaxKind[node.kind]);
    }
}
function getFunctionSignature(node) {
    const args = [];
    node.parameters.forEach((param) => {
        if (param.name === undefined) {
            throw new Error("Parameter has no name");
        }
        if (param.type === undefined) {
            throw new Error("Parameter has no type");
        }
        if (param.name.kind !== ts.SyntaxKind.Identifier) {
            throw new Error("Parameter name is not an identifier");
        }
        let identifier = param.name;
        args.push({
            name: identifier.text,
            type: getType(param.type)
        });
    });
    let returnType = simple("void");
    if (node.type !== undefined) {
        returnType = getType(node.type);
    }
    return { args, returnType, blockDesign: getJsDoc(node) };
}
function getFunction(node) {
    const name = node.name?.text;
    if (name === undefined) {
        throw new Error("Function has no name");
    }
    let type = getFunctionSignature(node);
    return { name, type: type };
}
function getVariable(node) {
    if (node.declarationList.declarations.length !== 1) {
        throw new Error("Variable statement has multiple declarations");
    }
    const decl = node.declarationList.declarations[0];
    if (decl.name === undefined) {
        throw new Error("Variable declaration has no name");
    }
    if (decl.name.kind !== ts.SyntaxKind.Identifier) {
        throw new Error("Variable name is not an identifier");
    }
    let identifier = decl.name;
    if (decl.type === undefined) {
        throw new Error("Variable declaration has no type");
    }
    if (decl.type.kind === ts.SyntaxKind.TypeReference) {
        return {
            name: identifier.text,
            type: getTypeReference(decl.type)
        };
    }
    if (decl.type.kind === ts.SyntaxKind.TypeLiteral) {
        return {
            name: identifier.text,
            type: {
                kind: "object",
                type: getTypeLiteral(decl.type)
            }
        };
    }
    throw new Error("Unsupported variable type: " + ts.SyntaxKind[decl.type.kind]);
}
function addArgument(bloc, arg, addLabel) {
    let input;
    switch (arg.type.kind) {
        case "simple":
            input = bloc
                .appendValueInput(arg.name)
                .setCheck(simpleCheck(arg.type.type));
            break;
        case "promise":
        case "array":
        case "object":
            input = bloc
                .appendValueInput(arg.name)
                .setCheck(arg.type.type);
            break;
        case "function":
            input = bloc
                .appendStatementInput(arg.name)
                .setCheck("Function");
            break;
        case "str_enum":
            {
                let i = bloc.appendDummyInput();
                if (addLabel) {
                    i.appendField(arg.name);
                }
                i.appendField(new Blockly.FieldDropdown(arg.type.type.map((value) => { return [value, value]; }), undefined), arg.name);
            }
            break;
        case "literal":
            bloc.appendDummyInput()
                .appendField(arg.type.type.value);
            break;
        default:
            throw new Error("Unsupported type: " + arg.type.kind);
    }
    if (addLabel && input) {
        input.appendField(arg.name);
    }
}
var SegmentKind;
(function (SegmentKind) {
    SegmentKind[SegmentKind["Text"] = 0] = "Text";
    SegmentKind[SegmentKind["Input"] = 1] = "Input";
    SegmentKind[SegmentKind["Newline"] = 2] = "Newline";
})(SegmentKind || (SegmentKind = {}));
function parseBlockConf(conf) {
    /**
     * Example config:
     * #jacly Configure adc\nPin {pin}\nAttenuation {attenuation}
     */
    let segments = [];
    conf = conf.trim();
    conf = conf.slice(6); // Remove #jacly
    let start = 0;
    let inBrackets = false;
    for (let i = 0; i < conf.length; i++) {
        if (conf[i] === "{") {
            if (inBrackets) {
                throw new Error("Nested brackets are not supported");
            }
            if (start !== i) {
                segments.push({ kind: SegmentKind.Text, value: conf.slice(start, i) });
            }
            start = i + 1;
            inBrackets = true;
        }
        else if (conf[i] === "}") {
            if (!inBrackets) {
                throw new Error("Unmatched closing bracket");
            }
            segments.push({ kind: SegmentKind.Input, value: conf.slice(start, i) });
            start = i + 1;
            inBrackets = false;
        }
        else if (conf[i] === "\n") {
            if (start !== i) {
                segments.push({ kind: SegmentKind.Text, value: conf.slice(start, i) });
            }
            segments.push({ kind: SegmentKind.Newline, value: "" });
            start = i + 1;
        }
    }
    if (start !== conf.length) {
        segments.push({ kind: SegmentKind.Text, value: conf.slice(start) });
    }
    return segments;
}
function addGlobalFunctionBlock(blocks, func) {
    let uname = getUniqueName(func.name);
    const block = {
        type: uname,
        init: (bloc) => {
            if (func.type.blockDesign === undefined) {
                bloc.appendDummyInput()
                    .appendField(func.name);
                for (let arg of func.type.args) {
                    addArgument(bloc, arg, true);
                }
            }
            else {
                bloc.setInputsInline(true);
                let conf = parseBlockConf(func.type.blockDesign);
                let args = new Set();
                for (let arg of func.type.args) {
                    args.add(arg.name);
                }
                for (let seg of conf) {
                    switch (seg.kind) {
                        case SegmentKind.Text:
                            bloc.appendDummyInput()
                                .appendField(seg.value);
                            break;
                        case SegmentKind.Input:
                            {
                                let arg;
                                for (let a of func.type.args) {
                                    if (a.name === seg.value) {
                                        arg = a;
                                        break;
                                    }
                                }
                                if (arg === undefined) {
                                    throw new Error("Argument not found: " + seg.value);
                                }
                                else {
                                    args.delete(arg.name);
                                }
                                addArgument(bloc, arg, false);
                            }
                            break;
                        case SegmentKind.Newline:
                            bloc.appendEndRowInput();
                            break;
                    }
                }
                if (args.size > 0) {
                    console.debug(args);
                }
            }
            bloc.setNextStatement(true);
            bloc.setPreviousStatement(true);
            if (!(func.type.returnType.kind === "simple" && func.type.returnType.type === "void")) {
                bloc.setOutput(true, typeToString(func.type.returnType));
            }
            bloc.setColour("404040");
        }
    };
    blocks.push(block);
    javascriptGenerator.forBlock[uname] = (block, generator) => {
        let args = [];
        for (let arg of func.type.args) {
            switch (arg.type.kind) {
                case "simple":
                    args.push(generator.valueToCode(block, arg.name, 0));
                    break;
                case "promise":
                case "array":
                case "object":
                    args.push(generator.valueToCode(block, arg.name, 0));
                    break;
                case "function":
                    {
                        let fn = arg.type.type;
                        let code = "(";
                        let first = true;
                        for (let a of fn.args) {
                            if (!first) {
                                code += ", ";
                            }
                            code += a.name;
                            first = false;
                        }
                        code += ") => {\n";
                        code += generator.statementToCode(block, arg.name);
                        code += "}";
                        args.push(code);
                    }
                    break;
                case "str_enum":
                    args.push(`"${block.getFieldValue(arg.name)}"`);
                    break;
                case "literal":
                    switch (arg.type.type.kind) {
                        case "number":
                            args.push(arg.type.type.value);
                            break;
                        case "boolean":
                            args.push(arg.type.type.value);
                            break;
                        case "string":
                            args.push(`"${arg.type.type.value}"`);
                            break;
                    }
                    break;
                default:
                    throw new Error("Unsupported type: " + arg.type.kind);
            }
        }
        return `${func.name}(${args.join(", ")});\n`;
    };
}
function addGlobalSimpleBlock(blocks, name, type) {
    let uname = getUniqueName(name);
    const block = {
        type: uname,
        init: (bloc) => {
            bloc.appendDummyInput()
                .appendField(name);
            bloc.setOutput(true, simpleName(type));
            bloc.setColour("404040");
        }
    };
    blocks.push(block);
    javascriptGenerator.forBlock[uname] = (block, generator) => {
        console.log("generate simple block for", uname);
        return [`(${name})`, 0];
    };
}
function addGlobalVariableBlock(blocks, var_) {
    switch (var_.type.kind) {
        case "simple":
            addGlobalSimpleBlock(blocks, var_.name, var_.type.type);
            break;
        case "object":
            addGlobalObjectBlock(blocks, var_.type.type, var_.name);
            break;
        default:
            throw new Error("Unsupported variable type: " + var_.type.kind);
    }
}
function addGlobalObjectBlock(blocks, obj, prefix = "") {
    for (let prop of obj.properties) {
        switch (prop.type.kind) {
            case "function":
                addGlobalFunctionBlock(blocks, {
                    name: prefix + "." + prop.name,
                    type: prop.type.type
                });
                break;
            case "simple":
                addGlobalVariableBlock(blocks, {
                    name: prefix + "." + prop.name,
                    type: prop.type
                });
                break;
            default:
                throw new Error("Unsupported property type: " + prop.type.kind);
        }
    }
}
function getModule(node) {
    if (node.name === undefined) {
        throw new Error("Module has no name");
    }
    if (node.body === undefined) {
        throw new Error("Module has no body");
    }
    if (node.body.kind !== ts.SyntaxKind.ModuleBlock) {
        throw new Error("Module body is not a block");
    }
    const name = node.name.text;
    const members = [];
    node.body.statements.forEach((member) => {
        if (member.kind === ts.SyntaxKind.VariableStatement) {
            members.push(getVariable(member));
        }
        else if (member.kind === ts.SyntaxKind.FunctionDeclaration) {
            const func = getFunction(member);
            members.push({
                name: func.name,
                type: { kind: "function", type: func.type }
            });
        }
    });
    return { name, members };
}
function addModuleBlock(blocks, mod, name) {
    for (let member of mod.members) {
        switch (member.type.kind) {
            case "function":
                addGlobalFunctionBlock(blocks, {
                    name: name + "." + member.name,
                    type: member.type.type
                });
                break;
            case "object":
                addGlobalObjectBlock(blocks, member.type.type, name + "." + member.name);
                break;
            default:
                throw new Error("Unsupported member type: " + member.type.kind);
        }
    }
}
export function getBlocks(dts) {
    console.log(dts);
    const ast = getAst(dts);
    const blocks = [];
    ast.forEachChild((node) => {
        if (node.kind === ts.SyntaxKind.FunctionDeclaration) {
            const func = getFunction(node);
            addGlobalFunctionBlock(blocks, func);
        }
        else if (node.kind === ts.SyntaxKind.VariableStatement) {
            const vars = getVariable(node);
            addGlobalVariableBlock(blocks, vars);
        }
        else if (node.kind === ts.SyntaxKind.ModuleDeclaration) {
            const mod = getModule(node);
            addModuleBlock(blocks, mod, mod.name);
        }
        else {
            console.log("Unsupported node: " + ts.SyntaxKind[node.kind]);
        }
    });
    return blocks;
}
